// React and Hooks
import React, { Suspense, lazy, useState, useEffect, useRef } from 'react';
// React Router DOM
import { BrowserRouter as Router, Routes, Route, Link, useLocation, useNavigate, useHistory } from 'react-router-dom';

// Components and Styles
import { Helmet } from 'react-helmet';
import Login from './login';
import { AuthProvider, useAuth } from './AuthContext';
import { auth } from './firebase';
import { GoogleAuthProvider, signInWithCredential } from 'firebase/auth';
import axios from 'axios';
import { API_BASE_URL } from './apiConfig';

import './App.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useSpring, useTransition, animated } from 'react-spring';
import { DownOutlined } from '@ant-design/icons';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale-extreme.css';
import { StripeSessionProvider } from './StripeSessionContext';
import { SubscriptionProvider } from './SubscriptionContext';
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Menu, MenuButton, MenuList, MenuItem, MenuGroup, MenuDivider, } from '@chakra-ui/react';
import { Alert, AlertIcon, AlertTitle, AlertDescription, CloseButton } from '@chakra-ui/react';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  Box,
  Icon,
  Flex,
  Container,
  IconButton,
  useColorMode,
  useColorModeValue,
  Drawer,
  Heading,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Spinner,
  DrawerCloseButton,
  Button,
  useDisclosure,
  useBreakpointValue,
  Text,
  VStack,
  Link as ChakraLink,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon, MoonIcon, SunIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { debounce } from 'lodash';
// import {
//     enable as enableDarkMode,
//     disable as disableDarkMode,
//     setFetchMethod as setFetch
// } from 'darkreader';
import { isChrome, isEdge, isFirefox } from 'react-device-detect';
import PhoneForwardedIcon from '@mui/icons-material/PhoneForwarded';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import LogoutTwoToneIcon from '@mui/icons-material/LogoutTwoTone';
import LoginTwoToneIcon from '@mui/icons-material/LoginTwoTone';
import InventoryTwoToneIcon from '@mui/icons-material/InventoryTwoTone';
import CarRepairTwoToneIcon from '@mui/icons-material/CarRepairTwoTone';
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import AppsTwoToneIcon from '@mui/icons-material/AppsTwoTone';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import SubscriptionsTwoToneIcon from '@mui/icons-material/SubscriptionsTwoTone';
import ContactEmergencyTwoToneIcon from '@mui/icons-material/ContactEmergencyTwoTone';
import CloudSyncTwoToneIcon from '@mui/icons-material/CloudSyncTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import MapsHomeWorkTwoToneIcon from '@mui/icons-material/MapsHomeWorkTwoTone';
import ScrollToTop from "react-scroll-to-top";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LocalPoliceTwoToneIcon from '@mui/icons-material/LocalPoliceTwoTone';
import LocalOfferIcon from '@mui/icons-material/NotificationAddTwoTone';
import Select from 'react-select';
import './index.css'

const Dashboard = lazy(() => import('./Dashboard'));
const LandingPage = lazy(() => import('./LandingPage'));
const PredictiveAnalyticsPage = lazy(() => import('./PredictiveAnalyticsPage'));
const DataVisualizationPage = lazy(() => import('./DataVisualizationPage'));
const AnomalyDetectionPage = lazy(() => import('./AnomalyDetectionPage'));
const SalesDashboardPage = lazy(() => import('./SalesDashboardPage'));
const SentimentAnalysisPage = lazy(() => import('./SentimentAnalysisPage'));
const HRAnalyticsPage = lazy(() => import('./HRAnalyticsPage'));
const FeaturesPage = lazy(() => import('./FeaturesPage'));
const PricingPage = lazy(() => import('./PricingPage'));
const AboutPage = lazy(() => import('./AboutPage'));
const ResourcesPage = lazy(() => import('./ResourcesPage'));
const ContactPage = lazy(() => import('./ContactPage'));
const CareersPage = lazy(() => import('./CareersPage'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const TermsOfService = lazy(() => import('./TermsOfService'));
const Security = lazy(() => import('./SecurityPage'));
const AuthActionHandler = lazy(() => import('./EmailVerification'));

const CenteredSpinner = () => {
  return (
    <Flex justify="center" align="center" height="100vh">
      <Spinner size="xl" color="blue.500" />
    </Flex>
  );
};

// Create your custom theme (if you have any customizations)
const theme = extendTheme({
  styles: {
    global: {
      // Apply the linear gradient to the body or specific components globally
      body: {
        background: 'linear-gradient(135deg, #eee9f7, #f2f0f5, #bbc4ed, #daebeb, #e8dcc8)',
        color: 'white',
      },
    },
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
});

// const DarkModeToggle = React.forwardRef((props, ref) => {
//   const [darkMode, setDarkMode] = useState(false);

//   useEffect(() => {
//     if (!isFirefox) {
//       setFetch(window.fetch);
//     }
//   }, []);

//   const toggleDarkMode = () => {
//     setDarkMode(!darkMode);
//     if (!isFirefox) {
//       if (!darkMode) {
//         enableDarkMode({
//           brightness: 100,
//           contrast: 90,
//           sepia: 10
//         });
//       } else {
//         disableDarkMode();
//       }
//     }
//   };

//   return (
//     <Tippy content="Toggle Dark Mode" animation="scale-extreme" theme="retro" delay={[500, 0]}>
//       <IconButton
//         ref={ref}
//         aria-label="Toggle Dark Mode"
//         icon={darkMode ? <SunIcon /> : <MoonIcon />}
//         onClick={toggleDarkMode}
//         size="md"
//         variant="ghost"
//       />
//     </Tippy>
//   );
// });

function AnimatedLink({ to, children }) {
  const location = useLocation();
  const props = useSpring({
    // Example animation: change the text color based on the active route
    color: location.pathname === to ? 'red' : 'blue'
  });

  return (
    <animated.div style={props}>
      <Link to={to}>{children}</Link>
    </animated.div>
  );
}

function scrollToNewsletter() {
    if (window.location.pathname !== '') {
        window.location.href = '/#newsletter';
        setTimeout(true, 500);
    }
    let lastHeight = 0, newHeight = document.body.scrollHeight;
    
    const checkHeight = () => {
        window.scrollTo(0, document.body.scrollHeight);
        newHeight = document.body.scrollHeight;
        
        if (newHeight !== lastHeight) {
            lastHeight = newHeight;
            setTimeout(checkHeight, 500); // Check every 100 milliseconds
        } else {
            const newsletterSection = document.getElementById('newsletter');
            if (newsletterSection) {
                newsletterSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    checkHeight();
}

function NotificationBanner() {
  const [isOpen, setIsOpen] = useState(true);

  // Key for session storage
  const NotificationBannerSessionStorageKey = 'notificationBannerClosed';

  useEffect(() => {
    // Check if the notification was already closed in this session
    const isClosed = sessionStorage.getItem(NotificationBannerSessionStorageKey) === 'true';
    if (isClosed) {
      setIsOpen(false);
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    // Save the closed state in session storage
    sessionStorage.setItem(NotificationBannerSessionStorageKey, 'true');
  };

  return isOpen ? (
    <Alert status="info" position="relative" align="center" bg='#a090fc'>
      <LocalOfferIcon style={{color:"#000"}} />
      <Box flex="1" fontSize="xs" color="#000" >
        <AlertTitle>Interactive Content and Free Tools!</AlertTitle>
        <AlertDescription display="block">
          Unlock our <strong>AI-driven business growth calculator</strong>—get personalized insights in minutes! <br />Sign up now and access this exclusive tool, plus actionable AI tips delivered straight to your inbox.
          &nbsp;
          <a href="#newsletter" style={{ textDecoration: 'underline', fontWeight:'bold'}} onClick={scrollToNewsletter}><i>Sign up now</i></a>.
        </AlertDescription>
      </Box>
      <CloseButton size='lg' border="2px solid"
        borderColor="gray.200"
        borderRadius="md"
        color='black' variant='outline' top="8px" onClick={handleClose} />
    </Alert>
  ) : null;
}

function AppHeader() {
  const location = useLocation();
  const { colorMode, toggleColorMode } = useColorMode();
  const btnBgColor = useColorModeValue('gray.200', 'gray.700');
  const navBg = useColorModeValue('gray.100', 'gray.900');
  const navColor = useColorModeValue('gray.600', 'white');
  const isUserLoggedIn = !!localStorage.getItem('user');
  // State to store the window width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // Determine font size based on window width
  const fontSize = windowWidth < 768 ? '14px' : '24px';
  const lineHeight = windowWidth < 768 ? '40px' : '70px';
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated, setIsAuthenticated, logout } = useAuth();
  const isActiveLink = (path) => location.pathname === path
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isAboutDropdownOpen, setAboutDropdownOpen] = useState(false);
  const [isProductDropdownOpen, setProductDropdownOpen] = useState(false);
  const [dropdownOpenStates, setDropdownOpenStates] = useState({});
  const aboutIconStyle = isAboutDropdownOpen ? { transform: 'rotate(180deg)' } : {};
  const btnRef = useRef();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const handleToggleDropdown = () => setAboutDropdownOpen(!isAboutDropdownOpen);

  const handleCredentialResponse = (response) => {
      const credential = GoogleAuthProvider.credential(response.credential);
      signInWithCredential(auth, credential).then((result) => {
          // User signed in
          console.log(result.user);
          navigate('/dashboard');
      }).catch((error) => {
          // Handle Errors here.
          console.error("Error signing in with credential: ", error);
      });
  };
  

  useEffect(() => {
    const loadGoogleOneTap = () => {
      const google = window.google;
      if (google && !isAuthenticated) {
        console.log('Google library loaded, initializing One Tap');

        google.accounts.id.initialize({
          client_id: '115952894250-i63pholh9pik40p9leub4g3k2d67hjhg.apps.googleusercontent.com',
          callback: handleCredentialResponse,
          use_fedcm_for_prompt: true
        });

        google.accounts.id.prompt((notification) => {
          if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
            console.log('One Tap prompt was not displayed:', notification);
          }
        }, { prompt_parent_id: 'google-one-tap-container' });
      }
    };

    const debouncedLoadGoogleOneTap = debounce(loadGoogleOneTap, 5000); // Adjust the delay as necessary

    if (!isAuthenticated) {
      // Load the Google One Tap script asynchronously
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.onload = debouncedLoadGoogleOneTap;
      document.body.appendChild(script);

      // Clean up the script and debounced function on component unmount
      return () => {
        document.body.removeChild(script);
        debouncedLoadGoogleOneTap.cancel();
      };
    }
  }, [isAuthenticated]); // Re-run the effect when isAuthenticated changes


  // const handleCredentialResponse = (response) => {
  //   console.log("Encoded JWT ID token: " + response.credential);
  //   // Handle the credential response, e.g., send to server for verification
  //   navigate('/dashboard');
  // };

  const handleKeyPress = (event) => {
      if (event.key === 'Enter' || event.key === 'Space') {
          handleToggleDropdown();
      }
  };

  useEffect(() => {
    const user = auth.currentUser;
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        // User is signed in, fetch the user hash from your Flask backend
        axios.post(`${API_BASE_URL}/generate-intercom-hash`, {
          userIdentifier: user.email,
        })
        .then(response => {
          const { hash } = response.data;
          // Initialize Intercom with the user hash for Identity Verification
          window.Intercom('boot', {
            app_id: 'u4vcvn7j', // Replace with your actual Intercom App ID
            email: user.email, // User's email
            // user_id: user.uid, // User's ID
            user_hash: hash, // The hash received from your Flask backend
          });
        })
        .catch(error => {
          console.error('Error fetching Intercom hash:', error);
        });
      } else {
        // User is signed out or session is cleared, adjust Intercom accordingly
        window.Intercom('shutdown');
        window.Intercom('boot', { app_id: 'u4vcvn7j' }); // Reboot Intercom for public users without user-specific information
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []); // Empty array ensures this effect only runs once on mount

  useEffect(() => {
    const canonicalLink = document.querySelector("link[rel='canonical']");
    const currentUrl = `https://www.peakmet.com${location.pathname}`;

    if (canonicalLink) {
      canonicalLink.href = currentUrl;
    } else {
      const head = document.querySelector('head');
      const newCanonicalLink = document.createElement('link');
      newCanonicalLink.rel = 'canonical';
      newCanonicalLink.href = currentUrl;
      head.appendChild(newCanonicalLink);
    }
  }, [location]); // Depend on location to update when it changes

  const handleMouseEnter = (key) => {
    setDropdownOpenStates(prev => ({ ...prev, [key]: true }));
  };

  const handleMouseLeave = (key) => {
    setDropdownOpenStates(prev => ({ ...prev, [key]: false }));
  };

  const handleToggleDarkMode = () => {
    if (!isFirefox) {
      toggleColorMode();
    }
  };

  const handleDropdownLinkClick = () => {
    setAboutDropdownOpen(false);
    setProductDropdownOpen(false);
  };

  const handleLogout = async () => {
    try {
      onClose();
      await logout(); // Assuming `logout` is an async function that handles the sign-out process
      navigate('/login'); // Navigate to the login page after logging out
    } catch (error) {
      console.error('Logout failed:', error);
      // Optionally, handle errors, such as displaying an error message to the user
    }
  };
  
  const aboutDropdownStyle = {
    position: 'absolute',
    color: 'white',
    shadow: 'md',
    rounded: 'md',
    color: '#000',
    zIndex: 10,
    width: 'auto', // Set a specific width or 'auto' for content width
    minWidth: '200px', // Optional: Set a minimum width
    fontSize: windowWidth < 768 ? '14px' : '16px',
  };

  const productsLinks = [
    { path: '/ai-ml-business-analytics-products', label: 'Off the Shelf', icon: ChevronRightIcon },
    { path: '/predictive-analytics-enhance-roi', label: 'Predictive Analytics', icon: ChevronRightIcon },
    { path: '/sales-dashboard-increase-conversions', label: 'Sales Dashboard', icon: ChevronRightIcon },
    { path: '/data-visualization-insights', label: 'Data Visualization', icon: ChevronRightIcon },
    { path: '/anomaly-detection-across-industries', label: 'Anomaly Detection', icon: ChevronRightIcon },
    { path: '/sentiment-analysis-improve-customer-experience', label: 'Sentiment Analysis', icon: ChevronRightIcon },
    { path: '/hr-analytics-talent-optimization', label: 'HR Analytics', icon: ChevronRightIcon },
    
  ];

  const navLinks = [
    { path: '/ai-ml-resources-for-businesses', label: 'Resources', icon: ChevronRightIcon },
    { path: '/ai-ml-solutions-pricing', label: 'Pricing', icon: ChevronRightIcon },
    
  ];

  const aboutLinks = [
    { path: '/about-peakmet-ai-solutions', label: 'About Us', icon: ChevronRightIcon },
    { path: 'https://blog.peakmet.com', label: 'Peakmet AI Blog', icon: ChevronRightIcon, isExternal: true },
    { path: '/ai-tech-careers-at-peakmet', label: 'Career', icon: ChevronRightIcon },
    { path: '/privacy-policy', label: 'Privacy Policy', icon: ChevronRightIcon },
    { path: '/terms-of-service', label: 'Terms Of Service', icon: ChevronRightIcon },
    { path: '/data-security-standards', label: 'Security', icon: ChevronRightIcon },
    { path: '/contact-peakmet-for-ai-solutions', label: 'Contact', icon: ChevronRightIcon },
  ];

    // Construct combined menu links, including "About" dropdown and authentication links
  const combinedLinks = [
    {
      path: '/ai-ml-business-analytics-products',
      label: 'Products',
      tip: 'Products',
      isDropdown: true,
      dropdownLinks: productsLinks,
    },
    ...navLinks,
    {
      path: '/about-peakmet-ai-solutions',
      label: 'Company',
      tip: 'Get to know',
      isDropdown: true,
      dropdownLinks: aboutLinks,
    },
    // Add conditional logic for authenticated user links
    ...(isAuthenticated 
      ? [
          {
            path: '/dashboard',
            label: 'Dashboard',
            tip: 'Back to Dashboard',
          },
          {
            path: '/logout',
            label: 'Logout',
            tip: 'Logout',
            action: handleLogout, // assuming handleLogout is a function that handles the logout logic
          },
        ]
      : [
          {
            path: '/login',
            label: 'Login/Signup',
            tip: 'Login/Signup',
          },
        ]
    ),
    // {
    //   label: 'Dark Mode',
    //   isToggle: true, // This flag will indicate a toggle action instead of navigation
    //   action: handleToggleDarkMode, // assuming handleToggleDarkMode is a function that handles the dark mode logic
    // },
  ];

  const style = {
    backgroundColor: hover ? '#45a049' : '#4CAF50',
    color: 'white',
    // ... other styles
  };

  const useScrollToTop = () => {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]); // Trigger the effect only when the pathname changes
  };
  useScrollToTop();

  console.log('isAuthenticated', isAuthenticated)

  const selectStyles = {
    container: (provided) => ({
      ...provided,
      fontSize: '24px',
      color: '#333',
      borderColor: '#ccc',
    }),
    // Add other inline styles for different parts of the Select component
  };

  // Style for the mobile menu icon, this should be moved to a CSS file
  const mobileMenuIconStyle = {
    display: 'block', // Show on mobile
    marginLeft: 'auto', // Align to the right
  };

  // Style for the desktop menu container, showing only on medium screens and up
  const desktopMenuStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    height: '100%',
    gap: 20,
  };

  // Inline styles for individual Menu items
  const menuItemStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    listStyle: 'none',
    margin: 0,
    padding: 0,
    fontFamily: 'GarnettMedium',
    color: 'black',
    height: '100%',
    fontSize: windowWidth < 1400 ? '16px' : '16px',
    cursor: 'pointer',
  };

  const activeLinkStyle = {
    textDecoration: 'underline',
    fontWeight: 'bold',
  };

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width to state
      setWindowWidth(window.innerWidth);
    }
    
    // Add event listener
    window.addEventListener('resize', handleResize);
    
    // Call handler right away so state gets updated with initial window size
    handleResize();
    
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  const logoStyle = {
    position: 'absolute',
    left: windowWidth >= 768 ? '50%' : '0',
    transform: windowWidth >= 768 ? 'translateX(-50%)' : 'none',
    top: '0', // Adjust as necessary
    zIndex: '10',
  };

  return (
   <Flex
    as="header"
    // Removed inline padding for responsiveness, moved to the style object below
    align="center"
    my="auto"
    tabIndex="0"
    justify={windowWidth >= 1000 ? 'space-between' : 'center'}
    style={{
      position: 'relative',
      //background: `linear-gradient(to bottom, #bbc4ed, #daebeb, #e8dcc8)`,
      backgroundColor: '#ffffff', 
      border: "1px solid",
      fontFamily: 'GarnettMedium',
      textTransform: 'uppercase',
      boxShadow: "0px 2px 2px -1px rgba(0, 0, 0, 0.2), 0px 2px 2px -2px rgba(0, 0, 0, 0.2)",
      borderColor: "rgba(0, 0, 0, 0.2)",
      backgroundPosition: 'center center',
      padding: windowWidth >= 768 ? '10px' : '5px', 
      width: '100%',
    }}
  >
    {windowWidth < 1000 && <Box flex="1"></Box>}
    <Box flexBasis={{ base: '150%', md: 'auto' }} flexGrow={1} display="flex" justifyContent="center">
      <div id="logo" >
        <Link to="/">
          <picture>
            <source srcSet="/webp/peakmet-logo-april-2024.webp" type="image/webp" />
            <img src="/peakmet-logo-april-2024.png" alt="PeakMet Logo" className="logo-img" />
          </picture>

        </Link>
      </div>
    </Box>
    <style>
      {`
        #logo .logo-img {
          width: 200px;
          // border-radius: 75px / 50px;
          border: 2px solid #fff;
          overflow: hidden;
          display: block;
          object-fit: cover;
          // box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
          margin-left: auto;
          margin-right: auto;
        }

        @media (max-width: 768px) {
          #logo .logo-img {
            width: 200px;
            margin-top: 0; /* Adjust if needed */
          }
        }
      `}
    </style>


    {/* Spacer Box to push button to the right */}
    {/*<Box flexGrow={99999} flexBasis={0} display={{ base: "none", md: "block" }} /> {/* Adjust display as per your breakpoint needs */}

    {/* Mobile Menu with Chakra UI Menu */}
    {windowWidth < 1200 ? (
      <>
      <Box display="flex" justifyContent="flex-end" flexGrow={1}>
        <Button
          ref={btnRef}
          leftIcon={<HamburgerIcon />}
          onClick={onOpen}
          size="lg"
          border="1px solid"
          boxShadow="0px 4px 6px -1px rgba(0, 0, 0, 0.4), 0px 2px 4px -2px rgba(0, 0, 0, 0.4)"
          borderColor="rgba(0, 0, 0, 0.2)"
          bgGradient="linear(65deg, #bed3fa, #edf0f5, #e5d7f5)" // Example gradient
          color="black" // Adjust text color as needed
          _hover={{
            bgGradient: "linear(65deg, #c1cce6, #c1cce6, #e5d7f5)", // Darker gradient on hover
          }}
          _active={{
            bgGradient: "linear(to-r, teal.700, teal.900)", // Even darker gradient for active state
          }}
        >
          Menu
        </Button>
      </Box>
      <Drawer isOpen={isOpen} placement='right' onClose={onClose} finalFocusRef={btnRef}>
        <DrawerOverlay
                bg='none'
                backdropFilter='auto'
                backdropInvert='80%'
                backdropBlur='2px'/>
        <DrawerContent
          bgGradient="linear(135deg, #f6ebf7, teal.50, #0971e0)"
          rounded="lg"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.9)"
          fontSize="lg"
        >
          <DrawerCloseButton size="lg" color="black" />
          <DrawerHeader
            bgGradient="linear(to-r, #f8e6fa, teal.50, blue.100)"
            color="black"
            rounded="lg"
            fontSize="xl"
            marginRight="0px"
          >
            Navigate<MapsHomeWorkTwoToneIcon sx={{ marginLeft: '0px' }}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </DrawerHeader>

          <DrawerBody>
            <VStack spacing={4} align="stretch">
              <Text fontSize="xl" color="black" mt={4} mb={2} pl={2} _hover={{
                bgGradient: "linear(to-r, teal.50, green.200)", color: "teal.900"
              }} style={{ cursor: 'pointer' }}><InventoryTwoToneIcon /> &nbsp;
                Products
              </Text>
              {productsLinks.map((productsLinks, index) => (
                <Button as={Link} to={productsLinks.path} key={`about-link-${index}`}
                        leftIcon={<Icon as={productsLinks.icon} />}
                        variant="ghost"
                        onClick={onClose}
                        justifyContent="start"
                        _hover={{ bgGradient: "linear(to-r, teal.50, green.200)", color: "teal.900" }}
                        _active={{ bgGradient: "linear(to-r, teal.100, green.300)", color: "teal.800" }}
                        size="lg">
                  {productsLinks.label}
                </Button>
              ))}
              <Text fontSize="xl" color="black" mt={4} mb={2} pl={2} _hover={{
                bgGradient: "linear(to-r, teal.50, green.200)", color: "teal.900"
              }} style={{ cursor: 'pointer' }}><CarRepairTwoToneIcon /> &nbsp;
                Tools
              </Text>
              {navLinks.map((link, index) => (
                <Button as={Link} to={link.path} key={`nav-link-${index}`} onClick={onClose} 
                        leftIcon={<Icon as={link.icon} />} variant="ghost" justifyContent="start"
                        _hover={{ bgGradient: "linear(to-r, teal.50, green.200)", color: "teal.900" }}
                        _active={{ bgGradient: "linear(to-r, teal.100, green.300)", color: "teal.800" }}
                        size="lg">
                  {link.label}
                </Button>
              ))}
              <Text fontSize="xl" color="black" mt={4} mb={2} pl={2} _hover={{
                bgGradient: "linear(to-r, teal.50, green.200)", color: "teal.900"
              }} style={{ cursor: 'pointer' }}><GroupsTwoToneIcon /> &nbsp;
                About
              </Text>
              {aboutLinks.map((aboutLink, index) => (
                <Button key={`about-link-${index}`} onClick={onClose}
                        leftIcon={<Icon as={aboutLink.icon} />}
                        variant="ghost"

                        as={aboutLink.path.startsWith("http") ? "a" : Link} 
                        to={!aboutLink.path.startsWith("http") ? aboutLink.path : undefined}
                        href={aboutLink.path.startsWith("http") ? aboutLink.path : undefined}
                        target={aboutLink.path.startsWith("http") ? "_blank" : "_self"}
                        rel={aboutLink.path.startsWith("http") ? "noopener noreferrer" : undefined}
                        isExternal={aboutLink.path.startsWith("http")}

                        justifyContent="start"
                        _hover={{ bgGradient: "linear(to-r, teal.50, green.200)", color: "teal.900" }}
                        _active={{ bgGradient: "linear(to-r, teal.100, green.300)", color: "teal.800" }}
                        size="lg">
                  {aboutLink.label}
                </Button>
              ))}
              {/* Toggle Dark Mode Button */}
              <Button
                leftIcon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                variant="ghost"
                justifyContent="start"
                onClick={toggleColorMode}
                fontFamily="GarnettMedium"
                size="lg"
                mt={8}
                _hover={{ bgGradient: "linear(to-r, teal.50, green.200)", color: "teal.900" }}
                _active={{ bgGradient: "linear(to-r, teal.100, green.300)", color: "teal.800" }}
              >
                Toggle Dark Mode
              </Button>
              {/* Authentication Button */}
              {isAuthenticated ? (
                <>
                <Button
                  leftIcon={<AppsTwoToneIcon />}
                  variant="ghost"
                  justifyContent="start"
                  fontFamily="GarnettMedium"
                  as={Link} to="/dashboard"
                  onClick={onClose}
                  size="lg"
                  fontSize="1.3rem"
                  mt={8}
                  _hover={{ bgGradient: "linear(to-r, teal.50, green.200)", color: "teal.900" }}
                  _active={{ bgGradient: "linear(to-r, teal.100, green.300)", color: "teal.800" }}
                >
                  Dashboard
                </Button>
                <Button
                  leftIcon={<LogoutTwoToneIcon />}
                  variant="ghost"
                  justifyContent="start"
                  fontFamily="GarnettMedium"
                  onClick={handleLogout}
                  size="lg"
                  fontSize="1.3rem"
                  mt={8}
                  mb={16}
                  _hover={{ bgGradient: "linear(to-r, teal.50, green.200)", color: "teal.900" }}
                  _active={{ bgGradient: "linear(to-r, teal.100, green.300)", color: "teal.800" }}
                >
                  Logout
                </Button>
                </>
              ) : (
                <Button
                  leftIcon={<LoginTwoToneIcon />}
                  variant="ghost"
                  color="teal"
                  fontFamily="GarnettMedium"
                  justifyContent="start"
                  fontSize="1.6rem"
                  mt={8}
                  mb={16}
                  onClick={() => {
                    onClose(); // Close the menu first
                    navigate('/login'); // Then navigate to the login page
                  }}
                  size="lg"
                  _hover={{ bgGradient: "linear(to-r, teal.50, green.200)", color: "teal.900" }}
                  _active={{ bgGradient: "linear(to-r, teal.100, green.300)", color: "teal.800" }}
                >
                  Login&nbsp;/ &nbsp;<p style={{ marginTop: '12px', color: "blue", fontSize:"1.8rem"
                   }}>Signup</p>
                </Button>
              )}
              <div style={{ padding: '20px', borderRadius: '10px', background: 'linear-gradient(to top, #458763, #010f07)', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
                <h4 style={{ marginBottom: '10px',
                    background: 'linear-gradient(to right, #f06, #48f)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    MozBackgroundClip: 'text',
                    MozTextFillColor: 'transparent'
                   }}>Follow Us</h4>
                  <p style={{ color: 'white', fontSize: '12px', margin: '10px 0' }}>
                    Connect with us on social media and delve into the forefront of AI technology. Discover strategic insights for business growth and explore our <a href="https://blog.peakmet.com/" target="_blank" style={{ color: '#48f' }}>Peakmet AI blog</a> for customers inspiring journey's. 
                  </p>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                  <a href="https://www.facebook.com/peakmetai" target="_blank" rel="noopener noreferrer" style={{ margin: '5px' }}>
                    <FacebookIcon style={{ color: 'black', width: 'auto', fontSize: '40px'}}/>
                  </a>
                  <a href="https://www.twitter.com/peakmetai" target="_blank" rel="noopener noreferrer" style={{ margin: '5px' }}>
                    <TwitterIcon style={{ color: 'black', width: 'auto', fontSize: '40px'}}/>
                  </a>
                  <a href="https://www.linkedin.com/company/peakmet/" target="_blank" rel="noopener noreferrer" style={{ margin: '5px' }}>
                    <LinkedInIcon style={{ color: 'black', width: 'auto', fontSize: '40px'}}/>
                  </a>
                  <a href="https://www.instagram.com/@yourcompany" target="_blank" rel="noopener noreferrer" style={{ margin: '5px' }}>
                    <InstagramIcon style={{ color: 'black', width: 'auto', fontSize: '40px'}}/>
                  </a>
                  <a href="https://www.youtube.com/@Peakmet" target="_blank" rel="noopener noreferrer" style={{ margin: '5px' }}>
                    <ContactEmergencyTwoToneIcon style={{ color: 'black', width: 'auto', fontSize: '40px'}}/>
                  </a>
                </div>
              </div>
            </VStack>
          </DrawerBody>

          <DrawerFooter justifyContent="center" bgGradient="linear(to-r, teal.100, blue.100)">
            <Button boxShadow='rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px' leftIcon={<CloseIcon />} size="lg" w="70%" onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      </>
      ) : (
      <>
      {/* Combined Desktop Menu */}
      <Box as="nav" className="desktop-menu" style={{ ...desktopMenuStyle, display: windowWidth >= 1200 ? 'flex' : 'none', marginBottom: '8px', marginTop: '8px' }}>
        {combinedLinks.map((link, index) => {
          const key = link.path || link.label || `link-${index}`;
          const isDropdownOpen = dropdownOpenStates[key]; // Determine if the current dropdown is open

          if (link.isDropdown) {
            return (
              <Box key={`${key}-box`}  tabIndex={isDropdownOpen ? "0" : "-1"}
                    role="button" aria-expanded={isDropdownOpen}
                    aria-haspopup="true" onKeyPress={handleKeyPress}
                  onMouseEnter={() => handleMouseEnter(key)} 
                  onMouseLeave={() => handleMouseLeave(key)} 
                  style={{ ...menuItemStyle, position: 'relative', marginRight: '20px', }}>
                <Flex>
                  <Text style={{ ...menuItemStyle }}>
                    {link.label}
                  </Text>
                  <Box as="span" style={{ marginLeft: '0.4rem', 
                  transition: 'transform 0.3s', 
                  transform: isDropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                   }}>
                    <ChevronDownIcon w="1.6rem" h="1.6rem" />
                  </Box>
                </Flex>
                {
                  isDropdownOpen && (
                    <Box bg='#ffffff' shadow="md" color="#000"
                    rounded="md" style={{ ...aboutDropdownStyle, top: '100%', left: 0 }}>
                      {link.dropdownLinks.map(dropdownLink => (
                        <Text as={dropdownLink.path.startsWith("http") ? "a" : Link} 
                              to={!dropdownLink.path.startsWith("http") ? dropdownLink.path : undefined}
                              href={dropdownLink.path.startsWith("http") ? dropdownLink.path : undefined}
                              key={dropdownLink.label}
                              whiteSpace="nowrap" 
                              overflow="hidden" 
                              target={dropdownLink.path.startsWith("http") ? "_blank" : "_self"}
                              rel={dropdownLink.path.startsWith("http") ? "noopener noreferrer" : undefined}
                              isExternal={dropdownLink.path.startsWith("http")}
                              textOverflow="ellipsis"
                              m={1}
                              style={{ fontFamily: 'GarnettMedium', color:"#000", display: 'block', fontWeight: location.pathname.includes(dropdownLink.path) ? 'bold' : 'normal',}}
                              _hover={{ textDecoration: 'underline', color: '#b6e3de', rounded: "md", padding: '15px', fontWeight: 'bold' }}
                              onClick={() => setDropdownOpenStates(prev => ({ ...prev, [key]: false }))}
                        >
                          {dropdownLink.label}
                        </Text>
                      ))}
                    </Box>
                  )
                }
              </Box>
            );
          } else if (link.isToggle) {
            // Dark mode toggle logic
            return (
              <Tippy key={`tippy-${index}`} 
                    content={colorMode === 'light' ? "Enable Dark Mode" : "Enable Light Mode"} animation="scale-extreme">
                <Box key={`${key}-box`} style={menuItemStyle} onClick={link.action}>
                  <IconButton
                    icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                    onClick={toggleColorMode}
                    bg={btnBgColor}
                  />
                </Box>
              </Tippy>
            );
          } else if (link.action) {
            // Action link logic (e.g., Logout)
            return (
              <Tippy key={link.tip} content={link.tip} animation="scale-extreme">
                <Box key={`${key}-box`} onClick={link.action}>
                  <Text rounded={'md'}
                      textDecoration={isActiveLink(link.path) ? 'underline' : 'none'}
                      _hover={{ textDecoration: 'underline', color: '#b6e3de', rounded: 'md', padding: '15px', fontWeight:'bold' }}
                      _active={{ bg: "#5c5a4a", rounded: 'md', color: "#616e51" }}
                      style={{ ...menuItemStyle, marginRight: '20px', fontWeight: isActiveLink(link.path) ? 'bold' : 'normal'}}>{link.label}</Text>
                </Box>
              </Tippy>
            );
          } else if (link.label === "Login/Signup") {
            // Regular link logic
            return (
              <Tippy key={`tippy-${index}`} content={link.tip ? link.tip: link.label} animation="scale-extreme">
                <Text as={Link} to={link.path} key={link.path}
                      rounded={'md'}
                      textDecoration={isActiveLink(link.path) ? 'underline' : 'none'}
                      _hover={{ textDecoration: 'underline', color: '#b6e3de', rounded: 'md', padding: '15px', fontWeight: 'bold' }}
                      _active={{ bg: "#5c5a4a", rounded: 'md', color: "#616e51" }}
                      style={{ ...menuItemStyle, 
                        color: '#520675', 
                        marginRight: '5rem',
                        fontWeight: isActiveLink(link.path) ? 'bold' : 'normal'}}
                    >
                  {link.label}
                </Text>
              </Tippy>
            );
          } else {
            // Regular link logic
            return (
              <Tippy key={`tippy-${index}`} content={link.tip ? link.tip: link.label} animation="scale-extreme">
                <Text as={Link} to={link.path} key={link.path}
                      rounded={'md'}
                      target={link.newTab ? "_blank" : "_self"}
                      rel={link.newTab ? "noopener noreferrer" : undefined}
                      textDecoration={isActiveLink(link.path) ? 'underline' : 'none'}
                      _hover={{ textDecoration: 'underline', color: '#b6e3de', rounded: 'md', padding: '15px', fontWeight: 'bold' }}
                      _active={{ bg: "#5c5a4a", rounded: 'md', color: "#616e51" }}
                      style={{ ...menuItemStyle, marginRight: '20px', fontWeight: isActiveLink(link.path) ? 'bold' : 'normal'}}
                    >
                  {link.label}
                </Text>
              </Tippy>
            );
          } 
        })}
      </Box>
      </>
      )}
  </Flex>
  );
}


function App() {

  const RouteTransition = ({ children }) => {
    const transitions = useTransition(children, {
      from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
      enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
      leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
    });

    return transitions((style, item) => 
      <animated.div style={style}>{item}</animated.div>
    );
  };

  useEffect(() => {
    const structuredData = {
      "@context": "http://schema.org",
      "@type": "Organization",
      "name": "PeakMet AI Analytics",
      "url": "https://www.peakmet.com",
      "logo": "https://www.peakmet.com/webp/logo6.webp",
      "description": "PeakMet AI Analytics specializes in providing AI and ML-driven insights for SMEs, enabling data-driven decision-making and operational efficiency.",
      "keywords": "AI analytics, machine learning, predictive analytics, data visualization, SME growth, operational efficiency, strategic market insights",
      "audience": {
        "@type": "Audience",
        "audienceType": "Small, Medium and Large-Scale Enterprises",
        "description": "Businesses seeking to leverage AI and ML for enhanced data analysis, operational insights, and strategic decision-making."
      },
      "offers": {
        "@type": "OfferCatalog",
        "name": "AI & ML Integration Services Catalog",
        "itemListElement": [
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Predictive Analytics",
              "description": "Harness predictive models to forecast trends, customer behaviors, and sales outcomes with high accuracy. Available across all subscription tiers for tailored needs.",
              "category": "Analytics",
              "areaServed": "Worldwide"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "HR Analytics",
              "description": "Optimize recruitment processes and workforce management through AI-driven insights into candidate selection and employee performance.",
              "category": "Human Resources",
              "areaServed": "Worldwide"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Sentiment Analysis",
              "description": "Understand customer sentiments, market trends, and brand perception through advanced sentiment analysis tools.",
              "category": "Customer Insights",
              "areaServed": "Worldwide"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Sales Dashboard",
              "description": "Access real-time sales analytics, performance metrics, and KPIs through intuitive dashboards to drive sales strategy and growth.",
              "category": "Sales Optimization",
              "areaServed": "Worldwide"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Anomaly Detection",
              "description": "Secure operations and data integrity by promptly identifying and addressing outliers, fraud, and anomalies.",
              "category": "Security",
              "areaServed": "Worldwide"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Data Visualization",
              "description": "Turn complex data sets into clear, insightful visual representations to support comprehension and decision-making.",
              "category": "Data Reporting",
              "areaServed": "Worldwide"
            }
          }
        ]
      },
        "hasOfferCatalog": {
        "@type": "OfferCatalog",
        "name": "AI & ML Subscription Services",
        "itemListElement": [
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Basic Subscription",
              "description": "Access to basic AI models, community support, and essential analytics tools.",
              "url": "https://www.peakmet.com/#/basic"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Pro Subscription",
              "description": "Includes all Basic features, plus advanced analytics, priority support, and more AI models.",
              "url": "https://www.peakmet.com/#/pro"
            }
          },
          {
            "@type": "Offer",
            "itemOffered": {
              "@type": "Service",
              "name": "Enterprise Subscription",
              "description": "Our premium offering with complete access to all AI and ML models, dedicated support, and bespoke solutions.",
              "url": "https://www.peakmet.com/#/enterprise"
            }
          }
        ]
      },
      "contactPoint": [
        {
          "@type": "ContactPoint",
          "telephone": "+1-(831) PEA-KMET",
          "contactType": "customer support",
          "areaServed": "Worldwide",
          "availableLanguage": ["English", "Spanish"]
        }
      ],
      "sameAs": [
        "https://www.facebook.com/peakmet",
        "https://www.twitter.com/peakmet",
        "https://www.linkedin.com/company/peakmet",
        "https://www.instagram.com/peakmet"
      ],
      "foundingDate": "2024-02-21",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "11111 San Jose Blvd Suite 56, #1089",
        "addressLocality": "Jacksonville",
        "addressRegion": "FL",
        "postalCode": "32223",
        "addressCountry": "US"
      },
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "5",
        "bestRating": "5",
        "reviewCount": "2"
      },
      "review": [
        {
          "@type": "Review",
          "author": {
            "@type": "Organization",
            "name": "BBB",
            "url": "https://www.bbb.org/"
          },
          "reviewRating": {
            "@type": "Rating",
            "ratingValue": "5",
            "bestRating": "5"
          },
          "reviewBody": "PeakMet AI Analytics is recognized for its exceptional customer service and innovative AI solutions, meeting the BBB’s standards for trust and transparency."
        },
        {
          "@type": "Review",
          "author": {
            "@type": "Organization",
            "name": "Trustpilot",
            "url": "https://www.trustpilot.com/"
          },
          "reviewRating": {
            "@type": "Rating",
            "ratingValue": "5",
            "bestRating": "5"
          },
          "reviewBody": "Customers rave about the impactful insights and enhancements to their operational efficiencies gained through PeakMet’s advanced analytics services."
        }
      ]
    };

    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify(structuredData);

    // Insert the script at the top of the <head>
    document.head.insertBefore(script, document.head.firstChild);

    return () => {
      // Clean up the script when the component unmounts
      document.head.removeChild(script);
    };
  }, []);

  return (
    <AuthProvider>
    <SubscriptionProvider>
    <StripeSessionProvider>
    <ChakraProvider theme={theme} resetCSS={true}>
    <Router>
      <div className="App">
        <NotificationBanner />
        {/* Header */}
        <AppHeader />
        <Box id="google-one-tap-container" style={{ position: 'fixed', top: '0', right: '0', zIndex: 1000 }}></Box>
        
        {/* Routes */}
        <Suspense fallback={<CenteredSpinner />}>
        <Routes>
          <Route path="/ai-ml-business-analytics-products/*" element={<RouteTransition><FeaturesPage /></RouteTransition>} />
          <Route path="/predictive-analytics-enhance-roi/*" element={<RouteTransition><PredictiveAnalyticsPage /></RouteTransition>} />
          <Route path="/data-visualization-insights/*" element={<RouteTransition><DataVisualizationPage /></RouteTransition>} />
          <Route path="/anomaly-detection-across-industries/*" element={<RouteTransition><AnomalyDetectionPage /></RouteTransition>} />
          <Route path="/sales-dashboard-increase-conversions/*" element={<RouteTransition><SalesDashboardPage /></RouteTransition>} />
          <Route path="/sentiment-analysis-improve-customer-experience/*" element={<RouteTransition><SentimentAnalysisPage /></RouteTransition>} />
          <Route path="/hr-analytics-talent-optimization/*" element={<RouteTransition><HRAnalyticsPage /></RouteTransition>} />
          <Route path="/ai-ml-solutions-pricing/*" element={<RouteTransition><PricingPage /></RouteTransition>} />
          <Route path="/ai-tech-careers-at-peakmet/*" element={<RouteTransition><CareersPage /></RouteTransition>} />
          <Route path="/about-peakmet-ai-solutions/*" element={<RouteTransition><AboutPage /></RouteTransition>} />
          <Route path="/ai-ml-resources-for-businesses/*" element={<RouteTransition><ResourcesPage /></RouteTransition>} />
          <Route path="/contact-peakmet-for-ai-solutions/*" element={<RouteTransition><ContactPage /></RouteTransition>} />
          <Route path="/login/*" element={<RouteTransition><Login /></RouteTransition>} />
          <Route path="/dashboard/*" element={<RouteTransition><Dashboard /></RouteTransition>} />
          <Route path="/privacy-policy/*" element={<RouteTransition><PrivacyPolicy /></RouteTransition>} />
          <Route path="/terms-of-service/*" element={<RouteTransition><TermsOfService /></RouteTransition>} />
          <Route path="/data-security-standards/*" element={<RouteTransition><Security /></RouteTransition>} />
          <Route path="/user-auth/*" element={<RouteTransition><AuthActionHandler /></RouteTransition>} />
          <Route path="/*" element={<RouteTransition><LandingPage /></RouteTransition>} />
        </Routes>
        </Suspense>

        {/* scroll-to-top button  */}
        <Tippy placement='bottom-start' arrow='true' content="scroll to top" animation="scale-extreme" theme="retro" >
          <Box>
            <ScrollToTop
              smooth
              component={<ArrowUpwardIcon />}
              style={{ boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px', 
              opacity: 0.5, background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', color: '#411a5e', bottom: '20px', left: '20px', zIndex: 1000 }}
            />
          </Box>
        </Tippy>

    <footer 
      style={{ 
        padding: '20px', 
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed', // This creates the parallax effect
        backgroundImage: 'url(/webp/peakmet-anomaly-detection.webp)',
        backgroundPosition: 'center',

      }}
    >
      <Container maxW="container.xl">
        <Box className="row">
          <Box className="col-md-4 col-sm-6 text-center" style={{ padding: '20px', borderRadius: '10px', background: 'linear-gradient(to top, #bd9f9d, #02041a)', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
            <h4 style={{ marginBottom: '10px',
                background: 'linear-gradient(to right, #f06, #48f)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                MozBackgroundClip: 'text',
                MozTextFillColor: 'transparent'
              }}>Quick Links</h4>
            <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
              <Link to="/about-peakmet-ai-solutions" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', margin: '5px', backgroundColor: '#3a3a5e', borderRadius: '5px', whiteSpace: 'nowrap', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', }}>
                  <ContactEmergencyTwoToneIcon style={{ marginRight: '8px' }} />
                  About Us
              </Link>
              <Link to="/ai-tech-careers-at-peakmet" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', margin: '5px', backgroundColor: '#3a3a5e', borderRadius: '5px', whiteSpace: 'nowrap', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', }}>
                  <CloudSyncTwoToneIcon style={{ marginRight: '8px' }} />
                  Jobs
              </Link> 
              <Link to="/data-security-standards" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', margin: '5px', backgroundColor: '#3a3a5e', borderRadius: '5px', whiteSpace: 'nowrap', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', }}>
                  <LocalPoliceTwoToneIcon style={{ marginRight: '8px' }} />
                  Security
              </Link> 
              <Link to="/contact-peakmet-for-ai-solutions" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', margin: '5px', backgroundColor: '#3a3a5e', borderRadius: '5px', whiteSpace: 'nowrap', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', }}>
                  <AccountTreeTwoToneIcon style={{ marginRight: '8px' }} />
                  Help
              </Link>   
            </Box>
          </Box>

           {/* Social Media Links Section */}
          <div className="col-md-4 col-sm-6 text-center" style={{ padding: '20px', borderRadius: '10px', background: 'linear-gradient(to top, #458763, #010f07)', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
            <h4 style={{ marginBottom: '10px',
                background: 'linear-gradient(to right, #f06, #48f)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                MozBackgroundClip: 'text',
                MozTextFillColor: 'transparent'
               }}>Follow Us</h4>
              <p style={{ color: 'white', fontSize: '12px', margin: '10px 0' }}>
                Connect with us on social media and delve into the forefront of AI technology. Discover strategic insights for business growth and explore our <a href="https://blog.peakmet.com/" target="_blank" style={{ color: '#48f' }}>Peakmet AI blog</a> for customers inspiring journey's. 
              </p>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
              <a href="https://www.facebook.com/peakmetai" target="_blank" rel="noopener noreferrer" style={{ margin: '5px' }}>
                <FacebookIcon style={{ color: 'black', width: 'auto', fontSize: '40px'}}/>
              </a>
              <a href="https://www.twitter.com/peakmetai" target="_blank" rel="noopener noreferrer" style={{ margin: '5px' }}>
                <TwitterIcon style={{ color: 'black', width: 'auto', fontSize: '40px'}}/>
              </a>
              <a href="https://www.linkedin.com/company/peakmet/" target="_blank" rel="noopener noreferrer" style={{ margin: '5px' }}>
                <LinkedInIcon style={{ color: 'black', width: 'auto', fontSize: '40px'}}/>
              </a>
              <a href="https://www.instagram.com/@yourcompany" target="_blank" rel="noopener noreferrer" style={{ margin: '5px' }}>
                <InstagramIcon style={{ color: 'black', width: 'auto', fontSize: '40px'}}/>
              </a>
              <a href="https://www.youtube.com/@Peakmet" target="_blank" rel="noopener noreferrer" style={{ margin: '5px' }}>
                <ContactEmergencyTwoToneIcon style={{ color: 'black', width: 'auto', fontSize: '40px'}}/>
              </a>
            </div>
          </div>

          <div className="col-md-4 col-sm-12 text-center" style={{ padding: '20px', borderRadius: '10px', background: 'linear-gradient(to top, #8c7566, #210d03)', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
              <h4 style={{
                marginBottom: '20px',
                background: 'linear-gradient(to right, #f06, #48f)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                MozBackgroundClip: 'text',
                MozTextFillColor: 'transparent'
              }}>Contact Information</h4>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              <div style={{ padding: '10px', backgroundColor: '#3a3a5e', borderRadius: '5px', color: 'white', marginBottom: '10px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
                <PhoneForwardedIcon /> +1-(831) PEAK-MET (732-5638)
              </div>
              <div style={{ padding: '10px', backgroundColor: '#3a3a5e', borderRadius: '5px', color: 'white', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
                <ForwardToInboxIcon /> info@peakmet.com
              </div>
            </div>
          </div>
        </Box>
        <div className="row copyright text-center" sx={{zIndex:0,}}>
          <div className="col-md-12" style={{padding: '5px', color: 'white', backgroundColor: '#3a3a5e', borderRadius: '5px', height: '40px', marginTop: '50px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)'}}>
            <p style={{color: 'white'}}>&copy; {(new Date().getFullYear())} PeakMet, Inc. All rights reserved.</p>
          </div>
        </div>
      </Container>
    </footer>
      </div>
    </Router>
    </ChakraProvider>
    </StripeSessionProvider>
    </SubscriptionProvider>
    </AuthProvider>
  );
}


export default App;
